import Error from "@@/common/Error.vue";
import { OSSUpload } from "@/utils/apiBase.js";
import { mdToast, GoBack } from "@/utils/tools";
import { getProjectRednoteProof, submitRednoteProof } from "@api/index";
export default {
  components: {
    Error
  },
  data() {
    return {
      showError: false,
      showMessage: '',
      rednote_draw_id: '',
      describe: '',
      proof_imgs: []
    };
  },
  async created() {
    window.userHeader.titleText("提交凭证");
    const id = this.$route.query.id ? this.$route.query.id : '';
    this.rednote_draw_id = id;
    if (!this.rednote_draw_id) {
      this.showError = true;
      this.showMessage = '未找到活动ID';
    } else {
      this.getProofList();
    }
  },
  methods: {
    // 获取已提交的凭证
    async getProofList() {
      const res = await getProjectRednoteProof({
        rednote_draw_id: this.rednote_draw_id
      });
      if (res.status == 0 && res.data) {
        this.proof_imgs = res.data.proof_imgs || [];
        this.describe = res.data.describe || '';
      }
    },
    afterRead(event) {
      let fileList = [];
      if (event.length) {
        event.forEach(item => {
          fileList.push(item.file);
        });
      } else {
        fileList = [event.file];
      }
      let that = this;
      let MDOSSoption = {
        success: function (item) {
          that.onSuccess({
            item
          });
        },
        error: function (e, msg) {
          mdToast(msg);
        }
      };
      OSSUpload(MDOSSoption, fileList);
    },
    onSuccess(item) {
      this.proof_imgs.push(item.item);
    },
    deleteImg(index) {
      this.proof_imgs.splice(index, 1);
    },
    // 提交
    async sub() {
      if (!this.proof_imgs.length) {
        mdToast("请上传凭证");
        return;
      }
      const obj = {
        rednote_draw_id: this.rednote_draw_id,
        describe: this.describe,
        proof_imgs: this.proof_imgs.join(",")
      };
      const res = await submitRednoteProof(obj);
      if (res.status == 0) {
        this.proof_imgs = res.data.proof_imgs || [];
        this.describe = res.data.describe || '';
        GoBack();
      } else {
        mdToast(res.message || '提交失败');
      }
    },
    goBack() {
      GoBack();
    }
  }
};